const RoutePaths = {
  HOME: '/home',
  LIST: '/list',
  PROFILE: '/profile/:id',
  ME: '/me',
  CHANGE_PASSWORD: '/change-password',
  WHO_WE_ARE: '/who-we-are',
  SIGN_UP: '/sign-up',
  LOGIN: '/login',
  REPORT: '/report/:id',
  INFORMATION: '/information',
  CONFIRM_DELETE: '/confirm-delete',
  LEGAL: '/legal',
  EMPTY_SEARCH: '/empty-search',
  WELCOME: '/welcome',
  CHAT: '/chat',
  CHAT_WITH_ID: '/chat/:id',
  FORGET_PASSWORD: '/forget-password',
  VERIFY: '/auth/:action/:value+',
  COOKIE_POLICY: '/cerez-politikasi',
  PRIVACY_POLICY: '/privacy-policy',
};

/*
const RoutePaths = {
  HOME: "/",
  LIST: "/arama",
  PROFILE: "/profil/:id",
  ME: "/ben",
  CHANGE_PASSWORD: "/sifre-degistir",
  WHO_WE_ARE: "/biz-kimiz",
  SIGN_UP: "/uye-ol",
  LOGIN: "/giris",
  REPORT: "/rapor/:id",
  INFORMATION: "/bilgi",
  CONFIRM_DELETE: "/silmeyi-onayla",
  LEGAL: "/sozlesme",
  EMPTY_SEARCH: "/bos-arama",
  WELCOME: "/hosgeldiniz",
  CHAT: "/mesajlasma",
  CHAT_WITH_ID: "/mesajlasma/:id",
  FORGET_PASSWORD: "/sifremi-unuttum",
  VERIFY: "/auth/:action/:value+",
};
*/

export default RoutePaths;
