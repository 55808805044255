import * as React from 'react';
import { Col, Row } from 'react-bootstrap';

import './PrivacyPolicy.scss';

function PrivacyPolicy() {
  return (
    <main className="Legal">
      <Row className="justify-content-center">
        <Col sm={12} md={9}>
          <h1>Privacy Policy</h1>
          <p>
            This privacy policy applies to the <strong>Bizdenkimvar!</strong> app (hereby referred to as "Application")
            for mobile devices, created by <strong>Tsight IT LTD</strong> (hereby referred to as "Service Provider") as
            a free service. This service is intended for use <strong>"AS IS"</strong>.
          </p>

          <h2>Information Collection and Use</h2>
          <p>
            The Application collects information when you download and use it. This information may include details such
            as:
          </p>
          <ul>
            <li>Your device's Internet Protocol address (e.g., IP address)</li>
            <li>The pages of the Application that you visit</li>
            <li>The time and date of your visit</li>
            <li>The time spent on those pages</li>
            <li>The operating system you use on your mobile device</li>
          </ul>
          <p>
            The Application collects your device's location, which helps the Service Provider determine your approximate
            geographical location and is used in the following ways:
          </p>
          <ul>
            <li>
              <strong>Geolocation Services:</strong> Location data is utilized to provide features such as personalized
              content, relevant recommendations, and location-based services.
            </li>
            <li>
              <strong>Analytics and Improvements:</strong> Aggregated and anonymized location data helps the Service
              Provider analyze user behavior, identify trends, and improve overall performance and functionality of the
              Application.
            </li>
            <li>
              <strong>Third-Party Services:</strong> Periodically, anonymized location data may be transmitted to
              external services to enhance the Application and optimize the Service Provider's offerings.
            </li>
          </ul>
          <p>
            The Service Provider may use the information you provide to contact you from time to time to provide
            important information, required notices, and marketing promotions.
          </p>
          <p>
            For a better experience while using the Application, the Service Provider may require you to provide certain
            personally identifiable information, including but not limited to your email, age, gender, and location. The
            information requested will be retained and used as described in this privacy policy.
          </p>

          <h2>Third-Party Services</h2>
          <p>
            Only aggregated, anonymized data is periodically transmitted to external services to aid the Service
            Provider in improving the Application and their service. The Application utilizes third-party services that
            have their own privacy policies regarding the handling of data. Below are links to the privacy policies of
            the third-party service providers used by the Application:
          </p>
          <ul>
            <li>
              <a href="https://policies.google.com/privacy">Google Play Services</a>
            </li>
            <li>
              <a href="https://www.facebook.com/about/privacy">Facebook</a>
            </li>
            <li>
              <a href="https://expo.dev/privacy">Expo</a>
            </li>
          </ul>

          <h2>Disclosure of Information</h2>
          <p>The Service Provider may disclose user-provided and automatically collected information:</p>
          <ul>
            <li>As required by law, such as to comply with a subpoena or similar legal process</li>
            <li>
              When they believe in good faith that disclosure is necessary to protect their rights, ensure your safety
              or the safety of others, investigate fraud, or respond to a government request
            </li>
            <li>
              With trusted service providers who work on their behalf, do not have an independent use of the information
              disclosed to them, and have agreed to adhere to the rules set forth in this privacy statement
            </li>
          </ul>

          <h2>Opt-Out Rights</h2>
          <p>
            You can stop all collection of information by the Application easily by uninstalling it. You may use the
            standard uninstall processes available as part of your mobile device or via the mobile application
            marketplace or network.
          </p>

          <h2>Data Retention Policy</h2>
          <p>
            The Service Provider will retain user-provided data for as long as you use the Application and for a
            reasonable time thereafter. If you’d like the Service Provider to delete user-provided data that you have
            provided via the Application, please contact them at{' '}
            <a href="mailto:ahmetalsan@gmail.com">ahmetalsan@gmail.com</a> and they will respond in a reasonable time.
          </p>

          <h2>Children's Privacy</h2>
          <p>
            The Service Provider does not knowingly solicit data from or market to children under the age of 13. The
            Application does not address anyone under the age of 13. In the case that the Service Provider discovers
            that a child under 13 has provided personal information, they will immediately delete this from their
            servers. If you are a parent or guardian and you are aware that your child has provided personal
            information, please contact the Service Provider at{' '}
            <a href="mailto:ahmetalsan@gmail.com">ahmetalsan@gmail.com</a> so that they can take the necessary actions.
          </p>

          <h2>Security</h2>
          <p>
            The Service Provider is concerned about safeguarding the confidentiality of your information. The Service
            Provider provides physical, electronic, and procedural safeguards to protect the information they process
            and maintain.
          </p>

          <h2>Changes to This Privacy Policy</h2>
          <p>
            This privacy policy may be updated from time to time for any reason. The Service Provider will notify you of
            any changes by updating this page with the new privacy policy. You are advised to consult this privacy
            policy regularly for any changes, as continued use is deemed approval of all changes.
          </p>
          <p>This privacy policy is effective as of 2024-10-17.</p>

          <h2>Your Consent</h2>
          <p>
            By using the Application, you are consenting to the processing of your information as set forth in this
            privacy policy now and as amended by the Service Provider.
          </p>

          <h2>Contact Us</h2>
          <p>
            If you have any questions regarding privacy while using the Application, or have questions about the Service
            Provider's practices, please contact them via email at{' '}
            <a href="mailto:ahmetalsan@gmail.com">ahmetalsan@gmail.com</a>.
          </p>
        </Col>
      </Row>
    </main>
  );
}

export default PrivacyPolicy;
