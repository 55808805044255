import * as React from 'react';

import Logo from '../../../assets/images/logo.svg';
import './Footer.scss';
import RoutePaths from '../../../constants/RoutePaths';
import { Link } from 'react-router-dom';

import appStoreIcon from '../../../assets/images/appstore-icon.png';
import googlePlayIcon from '../../../assets/images/google-play-icon.png';

function Footer() {
  return (
    <footer>
      <div className="logo">
        <Link to={RoutePaths.HOME}>
          <img alt="Logo" src={Logo} />
        </Link>
      </div>
      <div className="social-outer">
        <div className="social-wrapper">
          <a href="http://www.twitter.com/bizdenkimvar" target="_blank">
            <i className="fab fa-twitter fa-2x" />
          </a>
          <a href="https://www.instagram.com/bizdenkimvarnoktacom" target="_blank">
            <i className="fab fa-instagram fa-2x" />
          </a>
          <a href="mailto:destek@bizdenkimvar.com">
            <i className="fas fa-envelope fa-2x" />
          </a>
        </div>
        <div className="market-wrapper">
          <a href="https://apps.apple.com/tr/app/bizdenkimvar/id1536273626" target="_blank">
            <img className="icon" src={appStoreIcon} />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.bizdenkimvar.www" target="_blank">
            <img className="icon" src={googlePlayIcon} />
          </a>
        </div>
      </div>
      <div className="copyright">
        Copyright © Bizden Kim Var {new Date().getFullYear()} Tüm hakları saklıdır |{' '}
        <Link to={RoutePaths.LEGAL}>Kullanım Koşulları</Link>
      </div>
    </footer>
  );
}

export default Footer;
